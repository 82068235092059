import React, { useState } from 'react'
import Inputtt from '../SelectValue/Inputtt'
import getServices from '../../Services/get-services'
import Button from '../SelectValue/Button'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Loading from '../SelectValue/Loading';
import { ToastContainer, toast } from 'react-toastify';
function TrackOrderPage() {
    const [tracking, setTrackingNo] = useState('')
    const [order, setOrder] = useState({})
    const [trackValue, setTrackValue] = useState([])
    const [loading, setLoading] = useState(false)

    const onSubmit =(e) =>{
        e.preventDefault()
        setLoading(true)
        getServices.trackOrder(tracking).then(
         res =>{
            setLoading(false)
            if(res.data.statusCode === '200'){
                setOrder(res.data.result)
                setTrackValue(res.data.result.trackingHistory.reverse())
            } else{
                setLoading(false)
                alert(res.data.message)
            }
         }   
        ).catch(
            error =>{
                console.log(error)
            }
        )
    }
    const [activeStep, setActiveStep] = useState(0);
    
  return (
    <div className='sm:px-16 min-h-[80vh]   px-8 pt-20'>
    <Loading
        open={loading}
    />
     <ToastContainer/>
        <h1 className=' text-2xl mb-3  pt-10'>Track order </h1>
        <form onSubmit={onSubmit}>
        <div className=' grid sm:grid-cols-2 mb-3 gap-3'>
        <Inputtt
            label={"Enter tracking number"}
            placeholder={"6YGGUFV"}
            value={tracking}
            onChange={setTrackingNo}
        />
        <div>
       
        </div>
        
        </div>
        <Button
            name={'Track'}
        />
        </form>
        
       {order?.orderDetails && Object.keys(order.orderDetails).length > 0 && !loading  &&
       <div>
       <div className=' sm:w-[60%] mx-auto mt-4'>
       <h1 className=' text-2xl mb-3'>Order information</h1>
        <div className=' rounded-lg bg-[#000] p-5 sm:grid-cols-2 gap-5'>
            <div>
                <p className=' text-white mb-3 '>Name: {order?.orderDetails?.firstname}{order?.orderDetails?.lastname}</p>
            </div>
            <div>
                <p className=' text-white mb-3 '>Phone number: {order?.orderDetails?.phone_number}</p>
            </div>
            <div>
                <p className=' text-white mb-3 '>Email: {order?.orderDetails?.email_address}</p>
            </div>
            <div>
                <p className=' text-white mb-3 '>Address: {order?.orderDetails?.address}</p>
            </div>
            <div>
                <p className=' text-white mb-3 '>Total amount: {order?.orderDetails?.total_amount}</p>
            </div>
            <div>
                <p className=' text-white mb-3 '>Order date: {order?.orderDetails?.inserted_dt}</p>
            </div>
        </div>
        <div className=' rounded-lg bg-[#000] mb-5 mt-4 p-5 sm:grid-cols-2 gap-5'>
            {order?.productDetails?.map((e, index) =>{
                return <div key={index} className=' grid grid-cols-2 gap-4'>
                    <p className=' text-white mb-3 '>Name: {e.product_name}</p>
                    <p className=' text-white mb-3 '>Price: {e.price}</p>
                </div>
            })}
        </div>
        <div className=' rounded-lg bg-[#000] mb-5 p-5  gap-5'>
        <Stepper activeStep={activeStep} orientation="vertical">
        {trackValue.map((step, index) => (
          <Step key={step.id} completed={true}>
            <StepLabel
              
            >
            <p className=' text-white'>
            {step.message}
            </p>
            <p className=' text-white italic text-[12px]'>
            {step.inserted_dt}
            </p>
            
            </StepLabel>
            <StepContent>
             
              {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === products.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box> */}
            </StepContent>
          </Step>
        ))}
      </Stepper>
        </div>
       </div>
       </div>}
    </div>
  )
}

export default TrackOrderPage