import React from 'react'
import Footer from '../../Components/Footer'
import NavBar2 from '../../Components/Navbar2'
import CartShop from '../../Components/Cart/CartShop'

function Cart() {
  return (
    <div>
        <NavBar2/>
        <CartShop/>
        <Footer/>
    </div>
  )
}

export default Cart