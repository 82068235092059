import { Divider } from '@mui/material'
import React from 'react'
import { useCart } from '../../contex/CartContex';
import Two from '../../images/3.JPG'
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../SelectValue/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
function CartShop() {
    const { cartItems, removeFromCart, updateQuantity } = useCart();
    const totalOrderPrice = cartItems.reduce((total, item) => total + (item.quantity * item.price), 0);
  return (
    <div className='sm:px-16 min-h-[70vh] overflow-y-auto  px-8 pt-24'>
        <h1 className=' text-3xl font-bold  pt-10 text-center mb-4'>🔥 These products are limited, Checkout soon</h1>
        <div className=' hidden sm:grid grid-cols-4  mb-3 gap-4 '>
            <p className=' font-semibold  text-[14px]'>Product</p>
            <p className=' font-semibold text-center text-[14px]'>Price</p>
            <p className=' font-semibold text-center text-[14px]'>Quantity</p>
            <p className=' font-semibold text-center text-[14px]'>Subtotal</p>
        </div>
        <Divider/>
        <div>
        {cartItems.map(item => (
        <div key={item.id} className="cart-item grid mt-5 sm:grid-cols-4  mb-3 gap-4 ">
            <div className=' flex gap-4 '>
                <img src={item.image} width={100} height={100} alt={item.name}/>
                <div className=' flex flex-row justify-center'>
                <div>
                <h3 className=' text-bold mb-3 text-start'>{item.name}</h3>
                 <p className='  mb-3 text-start'>Size: {item.size}</p>
                 <button onClick={() => removeFromCart(item.id)}><DeleteIcon className=' text-red-700'/></button>
                 <div className=' block sm:hidden'>
                <p className=' text-bold text-center mb-3'>${item.price}</p>
            <div>
            <div className=' bg-gray-200 p-3 grid grid-cols-3 w-[100%] mx-auto gap-2 border rounded-lg'>
              <p  onClick={() => updateQuantity(item.id, -1)} className=' text-black  cursor-pointer'>-</p>
               <p className=' text-black text-center '>{item.quantity}</p> 
               <p onClick={() => updateQuantity(item.id, 1)} className=' text-black  text-end cursor-pointer'>+</p>
            </div>
            </div>
          <p className=' text-bold text-center mb-3'>$ {item.quantity * item.price}</p>
                </div>
                </div>
                
                </div>
            </div>
          
            <p className=' hidden sm:block text-bold text-center mb-3'>${item.price}</p>
            <div>
            <div className='hidden  bg-gray-200 p-3 sm:grid grid-cols-3 w-[70%] mx-auto gap-2 border rounded-lg'>
              <p  onClick={() => updateQuantity(item.id, -1)} className=' text-black  cursor-pointer'>-</p>
               <p className=' text-black text-center '>{item.quantity}</p> 
               <p onClick={() => updateQuantity(item.id, 1)} className=' text-black  text-end cursor-pointer'>+</p>
            </div>
            </div>
          <p className=' text-bold hidden sm:block text-center mb-3'>${item.quantity * item.price}</p>
        </div>
      ))}
        </div>
        {cartItems.length > 0 && 
          <div className=' mt-5 flex justify-end p-4 '>
        <div>
        <p className=' mb-5'>Total: <span className=' font-bold '>${totalOrderPrice}</span> </p>
            <Button
                name={<div>
                    Checkout <KeyboardArrowRightIcon/>
                </div>}
            />
        </div>
            
        </div>
        }

        {cartItems.length === 0 && <div>
          <p className=' my-10 text-2xl text-center text-red-600'> Your cart is empty</p>
        </div>}
       
    </div>
  )
}

export default CartShop