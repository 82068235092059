import React from 'react'

function Button2({onClick, name, ...rest}) {
  return (
    <>
      <button 
      className='  bg-white  hover:bg-[#FFB902] w-full hover:border hover:text-white  px-16 py-2.5 border rounded-lg text-black'
      onClick={onClick}
      {...rest}
      type='submit' 
     >{name}</button>   
    </>
  )
}

export default Button2