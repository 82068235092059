import React from 'react'
import Button from '../SelectValue/Button'
import { Link } from 'react-router-dom'

function ProductsCom({name, price, image, link}) {
  return (
    <div className=' my-4'>
        <img src={image} className=' max-h-[45vh] w-full rounded-lg' alt={name}/>
        <h1 className=' my-3 font-bold text-xl'>{name}</h1>
        <p className=' my-3 text-sm font-bold '>${price}</p>
        <Link to={link}>
        <Button
            name={"Select product"}
        />
        </Link>
        
    </div>
  )
}

export default ProductsCom