import React from 'react'
import TopSec from '../../Components/Home/Topsec'
import NavBar from '../../Components/Navbar'
import BeSpokeWears from '../../Components/Home/BespokeWear'
import Products from '../../Components/Home/Products'
import Footer from '../../Components/Footer'
import BookAppointment from '../../Components/Home/BookAppointment'

export default function Home() {
  return (
    <div>
    <NavBar/>
      <TopSec/>
      <BeSpokeWears/>
      <Products/>
      <BookAppointment/>
      <Footer/>
    </div>
  )
}
