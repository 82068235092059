import React from 'react'
import Logo from '../images/bilL.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <div className=' bg-black mt-16 sm:px-16 py-10  px-8 pt-10'>

        <div className=' grid sm:grid-cols-3 gap-3'>
            <div>
                <img src={Logo} className='mx-auto sm:mx-0' height={40} width={200} alt='logo'/>
            </div>
            <div>
            <p  className=' text-white block text-center'>Location</p>
            <p className=' text-white block text-center mb-3'> Canada 🇨🇦 Kamloops Bc </p>
            
            <p  className=' text-white block text-center'>Socials</p>
                <div className=' flex justify-center mt-3 gap-5'>
                <a href='https://www.instagram.com/bibaclothier/' target='_blank' rel='noreferrer'><InstagramIcon className=' text-white'/></a> 
                <a href='mailto: abc@example.com' target='_blank' rel='noreferrer'><EmailIcon className=' text-white'/></a>   
                <a href='tel:+17786944344' target='_blank' rel='noreferrer'><PhoneIcon className=' text-white'/></a> 
                </div>
            </div>
            <div>
                <Link to='/' className=' text-white block mb-4 text-center'>My account</Link>
                <Link to='/trackorder' className='text-white mb-4 block text-center'>Track order</Link>
                <Link to='/' className='text-white block mb-4 text-center'>Privacy policy</Link>
            </div>
        </div>
        <p className=' text-white block my-5'>© of Bibaclothier 2024. All rights Reserved</p>
    </div>
  )
}

export default Footer