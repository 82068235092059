import axios from "axios";
import authHeader from "./AuthHeader";
const BASEURL = "https://api.bibaclothier.com/v1/api"


const listAllProducts = () =>{
    return axios.get(BASEURL + `/list_all_product` , { headers: authHeader() })
}

const getSingleUsers = (id) =>{
    return axios.get(BASEURL + `/single_product?productId=${id}` , { headers: authHeader() })
}

const trackOrder = (id) =>{
    return axios.get(BASEURL + `/find_order?orderNo=${id}` , { headers: authHeader() })
}

const getServices = {
    
    listAllProducts,
    getSingleUsers,
    trackOrder
}

export default getServices