import React, { useEffect, useState } from 'react'
import ProductsCom from './ProductsCom'
import getServices from '../../Services/get-services'
function Products() {
  const [trans, setTrans] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() =>{
    setLoading(true)
    getServices.listAllProducts().then(
     res =>{
        setLoading(false)
        if(res.data.statusCode === '200'){
            setTrans(res.data.result)
        }
     }   
    ).catch(
        error =>{
            console.log(error)
        }
    )
}, [])
const preOrder = trans.filter((e) => e.category_id === 'PRE3877' && e.status === "0")
const readyMade = trans.filter((e) => e.category_id === 'REA7934' && e.status === "0")
  return (
    <div className='sm:px-16  px-8 pt-10'> 
        <h1 className=" text-[#FFB902] font-bold text-4xl mb-12"> Preorder Clothes</h1>
        <div className=' grid  sm:grid-cols-2 md:grid-cols-4 gap-4 overflow-x-auto'>
          {preOrder.map((e) =>{
            return <div  key={e.id}>
            <ProductsCom
            name={e.name}
            price={e.price}
            image={e.picture_1}
            link={`/products/details/${e.name}/pre-order/${e.product_id}/${e.price}`}
         />
            </div>
          })}
      
         
        </div>
        {preOrder.length === 0 && <p className=' text-center font-bold text-red-700 mt-5'>No Product... Check back shortly</p>}
        <h1 className=" text-[#FFB902] font-bold text-4xl my-12"> Readymade Clothes</h1>
        <div className=' grid sm:grid-cols-2 md:grid-cols-4 gap-4 overflow-x-auto'>
          {readyMade.map((e) =>{
            return <div  key={e.id}>
            <ProductsCom
            name={e.name}
            price={e.price}
            image={e.picture_1}
            link={`/products/details/${e.name}/readymade/${e.product_id}/${e.price}`}
         />
            </div>
          })}
      
        </div>
        {readyMade.length === 0 && <p className=' text-center font-bold text-red-700 mt-5'>No Product... Check back shortly</p>}
    </div>
  )
}

export default Products