import React from 'react'

function Button({name, onClick}) {
  return (
    <>

    <button 
      className=' bg-[#FFB902]   px-5 py-2.5 border-none rounded-lg font-bold text-white'
      onClick={onClick}
      type='submit' 
     >{name}</button>   
    </>
  )
}

export default Button