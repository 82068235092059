import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Checkout from "./Pages/Checkout";
import ProductDetails from "./Pages/ProductDetails";
import { CartProvider } from "./contex/CartContex";
import Cart from "./Pages/Cart";
import Scrolltotop from "./Components/Scrolltotop";
import TrackOrder from "./Pages/TrackOrder";


function App() {
  return (
  <div>
  <CartProvider>
  <BrowserRouter>
  <Scrolltotop/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/checkout" element={<Checkout/>}/>
      <Route path="/cart" element={<Cart/>}/>
      <Route path="/products/details/:name/:cat/:id/:price" element={<ProductDetails/>}/>
      <Route path="/trackorder" element={<TrackOrder/>}/>
    </Routes>
  </BrowserRouter>
  </CartProvider>
  </div>
  );
}

export default App;
