import React from 'react'
import ProductDetail from '../../Components/ProductDetails/ProductDetails'
import Footer from '../../Components/Footer'
import NavBar2 from '../../Components/Navbar2'


function ProductDetails() {
  return (
    <div>
    <NavBar2/>
      <ProductDetail/>
      <Footer/>
    </div>

  )
}

export default ProductDetails