
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Logo from '../images/bilL.png'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PersonIcon from '@mui/icons-material/Person';
import { useCart } from "../contex/CartContex";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  
  boxShadow: 24,
 
};
export default function NavBar() {
    const [navbar, setNavbar] = useState(false);
    const [clientWindowHeight, setClientWindowHeight] = useState("");
    const [changeBg, setChangeBg] = useState(false)
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    });
  
    const handleScroll = () => {
      setClientWindowHeight(window.scrollY);
    };
    const { cartCount } = useCart();
    useEffect(() =>{
      let backgroundTransparacyVar = clientWindowHeight / 610;
      if (backgroundTransparacyVar < 1) {
          setChangeBg(true)
      }
      else{
        setChangeBg(false)
      }
    }, [clientWindowHeight])

    return (
        <nav className= {changeBg ? `w-full bg-transparent fixed  z-40 shadow-md  `: `w-full z-40 fixed shadow-md bg-black   ` }>
            <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-4">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-3 md:block">
                        <Link to='/' >
                            <img src={Logo} width='152' height='30' className=" "  alt=" logo" /> 
                        </Link>
                        <div className="md:hidden">
                            {/* <button
                                className="p-2  text-white rounded-md outline-none bg-[#ed5252]  focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {!navbar && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 text-black h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button> */}
                            <div className=" flex gap-4">
                            <Link to={'/cart'}>
                              <div className=" px-3 relative ">

                              <LocalMallIcon className=" text-white"/>
                              <p className=" absolute top-0 right-2 bg-[#FF5959] rounded-[8px] text-[8px] px-[4px] py-[1px] font-bold text-white">{cartCount}</p>
                              </div>
                              </Link>
                            
                            <div className=" px-3 ">
                                <PersonIcon className=" text-white"/>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex justify-self-start pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                            navbar ? "hidden" : "hidden"
                        }`}
                    >
                    <div className="  flex justify-evenly">
                        <ul className="items-start justify-start space-y-8 md:flex md:space-x-6 md:space-y-0">
                           {/* <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 ' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }}   to='/invest'>
                                Invest
                                </NavLink> */}
                            
                          
                          
                        </ul>
                          
                        </div>
                    </div>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div className=" hidden md:flex md:order-2">
                <div className=" flex flex-col pr-3 justify-center">
                </div>
                <Link to={'/cart'}>
                <div className=" px-3 relative ">

                <LocalMallIcon className=" text-white"/>
                <p className=" absolute top-0 right-2 bg-[#FF5959] rounded-[8px] text-[8px] px-[4px] py-[1px] font-bold text-white">{cartCount}</p>
                </div>
                </Link>
              
               <div className=" px-3 ">
                  <PersonIcon className=" text-white"/>
               </div>
                </div>
                <Modal
        open={navbar}
       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='h-[95vh]  md:hidden'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className=" border-b-2 pb-2 px-4 pt-4 border-[#E6E6E7] flex justify-between">
            <div className=" mt-3">
            <img src={Logo}  className=" "  alt=" logo" />
            </div>
           
            <button
             className="p-2  text-white rounded-md outline-none bg-[#F5F7FA]  focus:border"
             onClick={() => setNavbar(!navbar)}
             >
            <svg
               xmlns="http://www.w3.org/2000/svg"
                 className="w-6 text-black h-6"
                   viewBox="0 0 20 20"
                  fill="currentColor"
                   >
                    <path
                     fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      />
            </svg>
            </button>
            </div>
          </Typography>
          <div  className="  mt-2">
           <div className=" p-4">
           <div className=" flex mb-3 md:order-2">
                <div className=" flex  justify-between">
               
                </div>
               
               </div>

               
                </div>
                <div className="  flex justify-start">
                        <ul className="items-start justify-start space-y-8 md:flex md:space-x-6 md:space-y-0">
                      
                                <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 block mt-6' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }}   to='/'>
                                Home
                                </NavLink>
                                <NavLink
                                className={({isActive}) =>{
                            return( ' text-back-color2 block' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }} 
                                 to='/invest'>
                                Invest
                                </NavLink>
                            <NavLink 
                            className={({isActive}) =>{
                            return( ' text-back-color2 block' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }} 
                            to='/funding'>
                               Get  funding
                                </NavLink>
                                <NavLink 
                            className={({isActive}) =>{
                            return( ' text-back-color2 block' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }} 
                            to='/listing'>
                              Listing
                                </NavLink>
                            <NavLink 
                            className={({isActive}) =>{
                            return( ' text-back-color2 block' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }} 
                            to='/aboutus'>
                              About
                                </NavLink>
                               
                            <NavLink
                            className={({isActive}) =>{
                            return( ' text-back-color2 block' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }} 
                             to='/blog'>
                               Blog
                                </NavLink>
                                <NavLink 
                            className={({isActive}) =>{
                            return( ' text-back-color2 block' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }} 
                            to='/faq'>
                               FAQ
                                </NavLink>
                               <NavLink 
                            className={({isActive}) =>{
                            return( ' text-back-color2 block' + (
                              !isActive ? ' hover:text-primary-color' : ' text-primary-color'
                            ))
                            }} 
                            to='/contactus'>
                                Contact us
                                </NavLink>
                        </ul>
                          
                        </div>
                <div className=" mt-24">

                </div>
           </div>
          
        </Box>
      </Modal>
            </div>
        </nav>


    );
}