import React from 'react'
import Button from '../SelectValue/Button'

function BookAppointment() {
  return (
    <div className='sm:px-16 my-16   px-8 pt-10'>
       <div className=' bg5 p-8 sm:px-20 rounded-lg sm:flex gap-6'>
        <div>
            <h3 className=' mb-3 text-white text-2xl'>Appointment Scheduler</h3>
            <p className=' text-white'>Do you want a custom style made specially for you ?</p>
        </div>
        <div className=' mt-3 sm:mt-0'>
        <a href="https://wa.me/+2348160843897?text=I'm%20interested%20in%20booking%20a%20session">
        <Button
            name={'Book Appointment'}
        />
        </a>
        
        </div>
       
       </div>
    </div>
  )
}

export default BookAppointment