import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../SelectValue/Button";

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class TopSec extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    render() {
      const settings = {
        
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        onmouseover:false,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      return (
        <div className=" ">
       
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div className=" bg-[#CCF2E180] pt-8 sm:pt-24 bg1 h-full" key={1}>
            <div className=" flex flex-col h-[60vh]  px-6 justify-end">
            <div className=" ">
            <p className=" text-xl text-white mb-2 ">Step into Style</p>
                <h1 className=" text-4xl text-white mb-2 ">Discover Bibaclothier's Latest Fashion Statements</h1>
                <Button
                    name={'Shop now'}
                />
            </div>
                
            </div>
            </div>
            <div className="bg-[#FCDBB4] pt-8 sm:pt-24 bg2 h-full" key={2}>
            <div className=" flex flex-col h-[60vh]  px-6 justify-end">
            <div className=" ">
            <p className=" text-xl text-white mb-2 ">Chic Vibes Only</p>
                <h1 className=" text-4xl text-white mb-2 ">Elevate Your Wardrobe with Bibaclothier's Signature Designs</h1>
                <Button
                    name={'Shop now'}
                />
            </div>
                
            </div>
            
            </div>
            <div className="bg-[#FCDBB4] pt-8 sm:pt-24 bg3 h-full" key={3}>
            <div className=" flex flex-col h-[60vh]  px-6 justify-end">
            <div className=" ">
            <p className=" text-xl text-white mb-2 ">Fashion Forward</p>
                <h1 className=" text-4xl text-white mb-2 ">Unveiling Bibaclothier's Trendsetting Creations.</h1>
                <Button
                    name={'Shop now'}
                />
            </div>
                
            </div>
           
            </div>
            <div className="bg-[#FCDBB4] pt-8 sm:pt-24 bg4 h-full" key={4}>
            <div className=" flex flex-col h-[60vh]  px-6 justify-end">
            <div className=" ">
            <p className=" text-xl text-white mb-2 ">Dive into Luxury</p>
                <h1 className=" text-4xl text-white mb-2 "> Indulge in Bibaclothier's Timeless Couture.</h1>
                <Button
                    name={'Shop now'}
                />
            </div>
                
            </div>
            </div>
          </Slider>
        
        </div>
      );
    }
  }