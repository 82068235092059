import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Slider from "react-slick";
import Two from '../../images/3.JPG'
import Three from '../../images/2.jpg'
import Button2 from '../SelectValue/Button2';
import Button from '../SelectValue/Button';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { Divider } from '@mui/material';
import { useCart } from '../../contex/CartContex';
import { useParams } from 'react-router-dom';
import getServices from '../../Services/get-services';
import Loading from '../SelectValue/Loading';
import Inputtt from '../SelectValue/Inputtt';
import { ToastContainer, toast } from 'react-toastify';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
   
    <div
    >
         <ArrowForwardIcon
           style={{ ...style, display: "block", color:"black"}}
       className={className}
          onClick={onClick}
           />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      
    >
      <ArrowBackIcon
      style={{ ...style, display: "block", color:"black"}}
       className={className}
          onClick={onClick}
           />
    </div>
  );
}
function ProductDetail() {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [size, setSize] = useState('')
  const [value, setValue] = useState(1)
  const decrement = () =>{
    if(value> 1){
      setValue(value - 1);
    } else{
      setValue(1)
    }
  }
  const increment = () =>{
    setValue(value + 1)
  }
  const { addToCart } = useCart();

  const handleAddToCart = (e) => {
    e.preventDefault()
    
    addToCart({ id: id,name: name, size:size, price: price, image: image1, 
       backShoulder:backShoulder, burst:burst,waist:waist, hip:hip, dressLenght:dressLenght,
       sNipple:sNipple,sBurst:sBurst, sWaist:sWaist,nippleNipple:nippleNipple}, value); // Pass the quantity to addToCart
       alert('Order added to cart')
  };
  const {name, id, price, cat} = useParams()

  const [loading, setLoading] = useState(false)
  const [image1, setImage1] = useState('')
  const [image2, setImage2] = useState('')
  const [backShoulder, setBackShoulder] = useState('')
  const [burst, setBurst] = useState('')
  const [waist, setwaist] = useState('')
  const [hip, setHip] = useState('')
  const [dressLenght, setDressLength] = useState('')
  const [sNipple, setShoulderNipple] = useState('')
  const [sBurst, setShoulderBurst] = useState('')
  const [sWaist, setShoulderWaist] = useState('')
  const [nippleNipple, setNippleNipple] = useState('')
  useEffect(() =>{
    setLoading(true)
    getServices.getSingleUsers(id).then(
     res =>{
        setLoading(false)
        if(res.data.statusCode === '200'){
             setImage1(res.data.result.product_details.picture_1)
             setImage2(res.data.result.product_details.picture_2)
        }
     }   
    ).catch(
        error =>{
            console.log(error)
        }
    )
}, [id])

  return (
    <div className='sm:px-16  px-8 pt-20'>
    <Loading
      open={loading}
    />
     <ToastContainer/>
    <div className='sm:hidden pt-12'>
        <Slider {...settings}>
            <div>
                <img src={image1} className=" px-2 sm:h-[70vh] mx-auto sm:w-[50%]"   alt="Product 2" key={2}/>
            </div>
            <div>
                <img src={image2}   className=" px-2 sm:h-[70vh] mx-auto sm:w-[50%]"   alt="Product 3" key={3}/>
            </div>
           
      </Slider>
        </div>
 <div className=' grid sm:grid-cols-2 pt-10 gap-6 '>
        <div className='hidden sm:block'>
        <Slider {...settings}>
            <div>
                <img src={image1} className=" px-2  mx-auto "  width={1000}  height={1000} alt="Product 2" key={2}/>
            </div>
            <div>
                <img src={image2}   className=" px-2  mx-auto "  width={1000}  height={1000}  alt="Product 3" key={3}/>
            </div>
           
      </Slider>
        </div>
        <div className=' px-8 sm:px-16 pt-10'>
           <h1 className=' text-3xl font-bold mb-3'>{name}</h1> 
           <p className=' textxl font-medium mb-3'>${price}</p>
           <form onSubmit={handleAddToCart}>
           { cat ==='readymade' ?
           <div className=' mb-3 pt-3'>
            <label className=' block  mb-3'>Size:</label>
            <select value={size}  onChange={(e) => setSize(e.target.value)} 
            className=' w-[70%] outline-none border rounded-lg bg-[#FAFBFC] border-gray-200 p-5' 
            required>
              <option value={''}>Choose an option </option>
              <option key={'S'} value={'S'}>S</option>
              <option key={'M'} value={'M'}>M</option>
              <option key={'L'} value={'L'}>L</option>
              <option key={'XL'} value={'XL'}>XL</option>
              <option key={'XXL'} value={'XXL'}>XXL</option>
            </select>
           </div> : cat ==='pre-order' ? <div className=' grid grid-cols-2 gap-3'>
            <Inputtt
              label={'Back to shoulder'}
              value={backShoulder}
              onChange={setBackShoulder}
            />
            <Inputtt
              label={'Bust'}
              value={burst}
              onChange={setBurst}
            />
             <Inputtt
              label={'Waist'}
              value={waist}
              onChange={setwaist}
            />
            <Inputtt
              label={'Hip'}
              value={hip}
              onChange={setHip}
            />
            <Inputtt
              label={'Dress length'}
              value={dressLenght}
              onChange={setDressLength}
            />

            <Inputtt
              label={'Shoulder to nipple'}
              value={sNipple}
              onChange={setShoulderNipple}
            />
             <Inputtt
              label={'Shoulder to bust'}
              value={sBurst}
              onChange={setShoulderBurst}
            />
            <Inputtt
              label={'Shoulder to waist'}
              value={sWaist}
              onChange={setShoulderWaist}
            />
            <Inputtt
              label={'Nipple to nipple'}
              value={nippleNipple}
              onChange={setNippleNipple}
            />
           </div> : null

           }
          
           <h1 className=' text-xl mb-3 font-semibold'>Quantity</h1>
           <div className=' grid grid-cols-2 mb-4 sm:grid-cols-4 gap-4'>
            <div className=' bg-gray-200 p-3 grid grid-cols-3 gap-2 border rounded-lg'>
              <p onClick={decrement} className=' text-black  cursor-pointer'>-</p>
               <p className=' text-black text-center '>{value}</p> 
               <p onClick={increment} className=' text-black  text-end cursor-pointer'>+</p>
            </div>
            <div className=' col-span-3'>
              <Button2
              //  disabled={size === '' ? true : false} 
              // onClick={handleAddToCart}
                name={'Add to cart'}
              />
            </div>
           </div>
           </form>
           <div className=' grid mb-3 grid-cols-1'>
            <Button
              name={"Buy now"}
            />
           </div>
           <Divider/>
           <div className=' pt-3 gap-3 flex'>
            <DeliveryDiningIcon/> 
            <p className=' font-extrabold'>Estimated Delivery:</p>
            <p>1 - 2 weeks</p>
           </div>
        </div>
    </div>
    </div>
   
  )
}

export default ProductDetail