

import React from "react";
import Slider from "react-slick";
import One from '../../images/3.JPG'

import Four from '../../images/7.jpg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import five from '../../images/4.JPG'
import six from '../../images/8.jpg'
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
     
      <div
      >
           <ArrowForwardIcon
             style={{ ...style, display: "block", color:"black"}}
         className={className}
            onClick={onClick}
             />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        
      >
        <ArrowBackIcon
        style={{ ...style, display: "block", color:"black"}}
         className={className}
            onClick={onClick}
             />
      </div>
    );
  }
function Responsive() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container sm:px-16  px-8 pt-10">
     <h1 className=" text-[#FFB902] font-bold text-4xl mb-5"> BESPOKE WEARS FOR ANY OCCASION</h1>
      <Slider {...settings}>
      <div>
                <img src={One} className=" px-2"  alt="Product 1" key={1}/>
            </div>
            {/* <div>
                <img src={Two} className=" px-2"   alt="Product 2" key={2}/>
            </div>
            <div>
                <img src={Three}  className=" px-2"   alt="Product 3" key={3}/>
            </div> */}
            <div>
                <img src={Four} className=" px-2"   alt="Product 4" key={4}/>
            </div>
            <div>
                <img src={five} className=" px-2"   alt="Product 5" key={5}/>
            </div>
            <div>
                <img src={six} className=" px-2"   alt="Product 6" key={6}/>
            </div>
            <div>
                <img src={five} className=" px-2"   alt="Product 5"/>
            </div>
      </Slider>
    </div>
  );
}

export default Responsive;
