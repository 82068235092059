import React from 'react'
import NavBar2 from '../../Components/Navbar2'
import Footer from '../../Components/Footer'
import TrackOrderPage from '../../Components/TrackOrder/TrackOrderPage'

function TrackOrder() {
  return (
    <div>
     <NavBar2/>   
     <TrackOrderPage/>
     <Footer/>
    </div>
  )
}

export default TrackOrder